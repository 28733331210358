const datatable = [
    {
        number: '030714',
        name: 'Askprin',
        form: 'Tablet',
        potency: '75mg',
        composition: 'Each enteric coated tablet contains:- Aspiri…75mg (USP Specification)',
        size: "3 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030715',
        name: 'Askprin',
        form: 'Tablet',
        potency: '150mg',
        composition: 'Each enteric coated tablet contains:- Aspiri…150mg (USP Specification)',
        size: "3 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030726',
        name: 'Askprol',
        form: 'Tablet',
        potency: '500mg',
        composition: 'Each uncoated tablet contains:- Paracetamol...500mg (B.P. Specification)',
        size: "20 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030725',
        name: 'Askprol',
        form: 'Suspension',
        potency: '120mg/5ml',
        composition: 'Each 5ml contains:- Paracetamol...120mg (USP Specification)',
        size: "60ml , 120ml",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030713',
        name: 'Avazin',
        form: 'Tablet',
        potency: '10mg',
        composition: 'Each film coated tablet contains:- Cetirizine 2HCl…10mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '035000',
        name: 'Cingol',
        form: 'Tablet',
        potency: '5mg',
        composition: 'Each uncoated tablet contains:- Montelukast Sodium equivalent to Montelukast...5mg (B.P. Specification)',
        size: "1 x 14's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '035201',
        name: 'Cingol',
        form: 'Tablet',
        potency: '10mg',
        composition: 'Each uncoated tablet contains:- Montelukast Sodium equivalent to Montelukast...10mg (B.P. Specification)',
        size: "1 x 14's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034991',
        name: 'Clopeg',
        form: 'Tablet',
        potency: '75mg',
        composition: 'Each film coated tablet contains:- Clopidogril Bisulphate equivalent to Clopidogril...75mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034983',
        name: 'Diaglim',
        form: 'Tablet',
        potency: '1mg',
        composition: 'Each uncoated tablet contains:- Glimipiride...1mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034984',
        name: 'Diaglim',
        form: 'Tablet',
        potency: '2mg',
        composition: 'Each uncoated tablet contains:- Glimipiride...2mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034985',
        name: 'Diaglim',
        form: 'Tablet',
        potency: '3mg',
        composition: 'Each uncoated tablet contains:- Glimipiride...3mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034986',
        name: 'Diaglim',
        form: 'Tablet',
        potency: '4mg',
        composition: 'Each uncoated tablet contains:- Glimipiride...4mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030729',
        name: 'Duotec',
        form: 'Tablet',
        potency: '150mg',
        composition: 'Each film coated tablet contains:- Ranitidine HCl equivalent to Ranitidine...150mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030730',
        name: 'Duotec',
        form: 'Tablet',
        potency: '300mg',
        composition: 'Each film coated tablet contains:- Ranitidine HCl equivalent to Ranitidine...300mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030720',
        name: 'Floxcip',
        form: 'Tablet',
        potency: '250mg',
        composition: 'Each film coated tablet contains:- Ciprofloxacin HCl equivalent to Ciprofloxacin...250mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030721',
        name: 'Floxcip',
        form: 'Tablet',
        potency: '500mg',
        composition: 'Each film coated tablet contains:- Ciprofloxacin HCl equivalent to Ciprofloxacin...500mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030719',
        name: 'Flurip',
        form: 'Tablet',
        potency: '100mg',
        composition: 'Each film coated tablet contains:- Flurbiprofen...100mg (B.P. Specification)',
        size: "3 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034992',
        name: 'Lenon',
        form: 'Tablet',
        potency: '250mg',
        composition: 'Each film coated tablet contains:- Levofloxacin hemihydrate equivalent to Levofloxacin...250mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034993',
        name: 'Lenon',
        form: 'Tablet',
        potency: '500mg',
        composition: 'Each film coated tablet contains:- Levofloxacin hemihydrate equivalent to Levofloxacin...500mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034998',
        name: 'Lopec',
        form: 'Tablet',
        potency: '50mg',
        composition: 'Each film coated tablet contains:- Losartan Potassium...50mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034999',
        name: 'Lopec',
        form: 'Tablet',
        potency: '100mg',
        composition: 'Each film coated tablet contains:- Losartan Potassium...100mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034994',
        name: 'Lopec-h',
        form: 'Tablet',
        potency: '50 & 12.50mg',
        composition: 'Each film coated tablet contains:- Losartan Potassium...50mg Hydrochlorothiazide...12.50mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030723',
        name: 'Modopine',
        form: 'Tablet',
        potency: '5mg',
        composition: 'Each uncoated tablet contains:- Amlodipine Besilate equivalent to Amlodipine...5mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '030724',
        name: 'Modopine',
        form: 'Tablet',
        potency: '10mg',
        composition: 'Each uncoated tablet contains:- Amlodipine Besilate equivalent to Amlodipine...10mg (USP Specification)',
        size: "2 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034995',
        name: 'Ponfab',
        form: 'Tablet',
        potency: '250mg',
        composition: 'Each uncoated tablet contains:- Mefanamic Acid...250mg (S.P. Specification)',
        size: "60 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034996',
        name: 'Ponfab',
        form: 'Tablet',
        potency: '500mg',
        composition: 'Each uncoated tablet contains:- Mefanamic Acid...500mg (S.P. Specification)',
        size: "10 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034987',
        name: 'Ponfab',
        form: 'Suspension',
        potency: '50mg/5ml',
        composition: "Each 5ml contains:- Mefanamic Acid...500mg (Manufacturer's Specification)",
        size: "60ml",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034988',
        name: 'Rocoz',
        form: 'Tablet',
        potency: '10mg',
        composition: 'Each film coated tablet contains:- Simvastatin...10mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034989',
        name: 'Rocoz',
        form: 'Tablet',
        potency: '20mg',
        composition: 'Each film coated tablet contains:- Simvastatin...20mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034990',
        name: 'Rocoz',
        form: 'Tablet',
        potency: '40mg',
        composition: 'Each film coated tablet contains:- Simvastatin...40mg (USP Specification)',
        size: "1 x 10's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '035202',
        name: 'Cefask',
        form: 'Capsule',
        potency: '400mg',
        composition: 'Each Capsule contains:- Cefixime Trihydrate equivalent to Cefixime...400mg (JP Specification)',
        size: "1 x 5's",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '035203',
        name: 'Cefask',
        form: 'Suspension',
        potency: '100mg',
        composition: 'Each 5ml contains:- Cefixime Trihydrate equivalent to Cefixime...100mg (USP Specification)',
        size: "30ml",
        rDate: "16/08/2021",
        nDate: "16/08/2026",
    },
    {
        number: '034997',
        name: 'Promy',
        form: 'Capsule',
        potency: '20mg',
        composition: 'Each Capsules contains:- Omeprazole Pellets...20mg (USP Specification)',
        size: "100's, 1 x 14's",
        rDate: "19/07/2022",
        nDate: "19/07/2027",
    },
    {
        number: '107232',
        name: 'Citiquin',
        form: 'Tablet',
        potency: '200mg',
        composition: 'Each Film coated tablet contains:- Hydroxychloroquine Sulfate...200mg (USP Specification)',
        size: "30's, 50's",
        rDate: "26/03/2021",
        nDate: "26/03/2026",
    },
    {
        number: '107233',
        name: 'Citi-Chew',
        form: 'Tablet',
        potency: '500mg',
        composition: 'Each Film coated tablet contains:- Ascorbic Acid...500mg (USP Specification)',
        size: "40's",
        rDate: "26/03/2021",
        nDate: "26/03/2026",
    },
    {
        number: '111660',
        name: 'Lanask',
        form: 'Capsule',
        potency: '15mg',
        composition: 'Each Capsules contains:- Lansoprazole (as enteric coated pellets)...15mg (USP Specification)',
        size: "1 x 10's ",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111661',
        name: 'Lanask',
        form: 'Capsule',
        potency: '30mg',
        composition: 'Each Capsules contains:- Lansoprazole (as enteric coated pellets)...30mg (USP Specification)',
        size: "14's ",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111662',
        name: 'Flucogals',
        form: 'Capsule',
        potency: '200mg',
        composition: 'Each Capsules contains:- Fluconazole...200mg (BP Specification)',
        size: "4's ",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111663',
        name: 'Flucogals',
        form: 'Capsule',
        potency: '50mg',
        composition: 'Each Capsules contains:- Fluconazole...50mg (BP Specification)',
        size: "7's ",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111664',
        name: 'Flucogals',
        form: 'Dry Powder Suspension',
        potency: '50mg/5ml',
        composition: 'Each 5ml contains:- Fluconazole...50mg (USP Specification)',
        size: "35ml",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111665',
        name: 'Flucogals',
        form: 'Capsule',
        potency: '150mg',
        composition: 'Each Capsules contains:- Fluconazole...150mg (USP Specification)',
        size: "1's, 2's, 4's",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111666',
        name: 'Askind',
        form: 'Tablet',
        potency: '200mg',
        composition: 'Each Film coated tablet contains:- Metronidazole...200mg (USP Specification)',
        size: "200's",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111667',
        name: 'Askind',
        form: 'Tablet',
        potency: '400mg',
        composition: 'Each Film coated tablet contains:- Metronidazole...400mg (USP Specification)',
        size: "200's",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111668',
        name: 'Zyask',
        form: 'Capsule',
        potency: '250mg',
        composition: 'Each Capsule contains:- Azithromycin...250mg (USP Specification)',
        size: "6's",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111669',
        name: 'Zyask',
        form: 'Dry Powder Suspension',
        potency: '200mg/5ml',
        composition: 'Each 5ml contains:- Azithromycin...200mg (USP Specification)',
        size: "15ml",
        rDate: "16/02/2022",
        nDate: "16/02/2027",
    },
    {
        number: '111979',
        name: 'Lonirupol',
        form: 'Tablet',
        potency: '100mg',
        composition: 'Each tablet contains:- Allopurinol...100mg (USP Specification)',
        size: "50's",
        rDate: "22/06/2022",
        nDate: "22/06/2027",
    },
    {
        number: '111980',
        name: 'Lonirupol',
        form: 'Tablet',
        potency: '300mg',
        composition: 'Each tablet contains:- Allopurinol...300mg (USP Specification)',
        size: "20's",
        rDate: "22/06/2022",
        nDate: "22/06/2027",
    },
    {
        number: '113233',
        name: 'Escitram',
        form: 'Tablet',
        potency: '10mg',
        composition: 'Each Film coated tablet contains:- Escitram as Oxalate...10mg (USP Specification)',
        size: "10's",
        rDate: "16/09/2022",
        nDate: "16/02/2027",
    },
    {
        number: '113234',
        name: 'Proask',
        form: 'Suspension',
        potency: '100mg/5ml',
        composition: 'Each 5ml contains:- Ibuprofen...100mg (USP Specification)',
        size: "90ml",
        rDate: "16/09/2022",
        nDate: "16/02/2027",
    },
    {
        number: '115232',
        name: 'Triaxonecit',
        form: 'Injection',
        potency: '1g (IM)',
        composition: 'Sterile Powder of Ceftriaxone Sodium e.q. to Ceftriaxaone...1g (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115233',
        name: 'Triaxonecit',
        form: 'Injection',
        potency: '1g (IV)',
        composition: 'Sterile Powder of Ceftriaxone Sodium e.q. to Ceftriaxaone...1g (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115234',
        name: 'Triaxonecit',
        form: 'Injection',
        potency: '2g (IV)',
        composition: 'Sterile Powder of Ceftriaxone Sodium e.q. to Ceftriaxaone...2g (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115235',
        name: 'Triaxonecit',
        form: 'Injection',
        potency: '250mg (IM)',
        composition: 'Sterile Powder of Ceftriaxone Sodium e.q. to Ceftriaxaone...250mg (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115236',
        name: 'Triaxonecit',
        form: 'Injection',
        potency: '250mg (IV)',
        composition: 'Sterile Powder of Ceftriaxone Sodium e.q. to Ceftriaxaone...250mg (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115237',
        name: 'Triaxonecit',
        form: 'Injection',
        potency: '500mg (IM)',
        composition: 'Sterile Powder of Ceftriaxone Sodium e.q. to Ceftriaxaone...500mg (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115238',
        name: 'Triaxonecit',
        form: 'Injection',
        potency: '500mg (IV)',
        composition: 'Sterile Powder of Ceftriaxone Sodium e.q. to Ceftriaxaone...500mg (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115239',
        name: 'Taximecit',
        form: 'Injection',
        potency: '1g',
        composition: 'Sterile Powder of Cefotaxime Sodium e.q. to Cefotaxime...1g (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115240',
        name: 'Taximecit',
        form: 'Injection',
        potency: '250mg',
        composition: 'Sterile Powder of Cefotaxime Sodium e.q. to Cefotaxime...250mg (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
    {
        number: '115241',
        name: 'Taximecit',
        form: 'Injection',
        potency: '500mg',
        composition: 'Sterile Powder of Cefotaxime Sodium e.q. to Cefotaxime...500mg (USP Specification)',
        size: "1's",
        rDate: "02/01/2023",
        nDate: "02/01/2028",
    },
]

export default datatable;