import React from 'react'
import Layout from './Layout'
import ImpactPdfFile from '../../assets/ImpactReport.pdf'

export default function ImpactReport() {
    return (
        <>
            <Layout title={"Citi Pharma: Impact Report (2023)"} description={"Access Citi Pharma's Impact Report for (2023). Explore our financial performance, achievements, and future goals."} keywords={"impact report, Citi Pharma"}>

                <div className='pdf'>
                    <h1 className='mainHeadings mt-5'>Imapct Report 2023</h1>
                    <iframe src={ImpactPdfFile} title="Impact Report 2023" className='h-100 w-100' />
                </div>

            </Layout >
        </>
    )
}