import React from 'react'
import Layout from './Layout'
import pdfFile from '../../assets/PROXY-FORM.pdf'

export default function ProxyForm() {
    return (
        <>
            <Layout title={"Citi Pharma: Proxy Form (2024)"} description={"Access Citi Pharma's Proxy Form for (2024). Explore our financial performance, achievements, and future goals."} keywords={"Proxy Form, Citi Pharma"}>

                <div className='pdf'>
                    <h1 className='mainHeadings mt-5'>Proxy Form</h1>
                    <iframe src={pdfFile} title="Proxy Form" className='h-100 w-100' />
                </div>
            </Layout >
        </>
    )
}
