import { useState } from "react";
import { usePopper } from "react-popper";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  feedback: "",
}

export default function Feedback() {
  const [showWidget, setShowWidget] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [10, 10] } }],
  });

  // Send Feedback
  const [state, setState] = useState(initialState);

  const handleChange = e => {
    setState(s => ({ ...s, [e.target.name]: e.target.value }))
  }

  // User id
  emailjs.init("MvVDaE2TC241ryI16");
  // emailjs.init(process.env.REACT_APP_USER_ID_2);

  const handleFeedback = (e) => {
    e.preventDefault();
    let { name, email, feedback } = state
    name = name.trim()
    email = email.trim()
    feedback = feedback.trim()

    const errors = {};

    if (name.length < 3) {
      errors.name = "Please enter a name with at least 3 characters.";
    } else if (/^[0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?]/.test(name)) {
      errors.name = "Name cannot start with a number or special character.";
    }
    if (!email) {
      errors.email = "Please enter an email.";
    } else if (/^[0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?]/.test(email)) {
      errors.email = "Email cannot start with a number or special character.";
    } else if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!feedback) {
      errors.feedback = "Please enter feedback.";
    }

    // If there are errors, display them and prevent form submission
    if (Object.keys(errors).length > 0) {
      // Display error messages
      Object.keys(errors).forEach(key => {
        window.notify(errors[key], "error");
      });
    } else {
      // emailjs.send(process.env.REACT_APP_SERVICE_ID_2, process.env.REACT_APP_F_TEMPLATE_ID, {
      emailjs.send("service_citi", "Citi_Pharma_Feedback", {
        name,
        email,
        feedback
      })
        .then((response) => {
          window.notify("Feedback sent successfully!", "success");
        })
        .catch((error) => {
          console.error('Error sending feedback:', error);
          window.notify("Error sending feedback.", "error");
        });

      setState(initialState);
    }
  };

  // Helper function to check if email is valid
  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <div className="feedback-container d-flex flex-column align-items-center justify-content-center">
      <button
        onClick={() => setShowWidget(!showWidget)}
        ref={setReferenceElement}
        className="feedback-button btn"
        type="button"
      >
        <span className="rotate">Feedback</span>
      </button>
      {showWidget && (
        <div
          className="feedback-form bg-white p-3 rounded shadow-lg"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <form className="form-group">
            <input
              type="text"
              name="name"
              className="form-control mb-2 outline-0 shadow-none"
              placeholder="Enter your name"
              value={state.name}
              onChange={handleChange}
            />
            <input
              type="email"
              name="email"
              className="form-control mb-2 outline-0 shadow-none"
              placeholder="Enter your email"
              value={state.email}
              onChange={handleChange}
            />

            <textarea
              name="feedback"
              className="form-control mb-2 outline-0 shadow-none"
              placeholder="Enter your feedback"
              rows="4"
              value={state.feedback}
              onChange={handleChange}
            ></textarea>

            <button
              className="btn btn-primary w-100 rounded-0"
              onClick={handleFeedback}
            >
              Send Feedback
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
