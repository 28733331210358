import React from 'react'
import Layout from './Layout'
import pdfFile from '../../assets/Notices.pdf'

export default function Notices() {
    return (
        <>
            <Layout title={"Citi Pharma: Notices (2024)"} description={"Access Citi Pharma's Notices for (2024). Explore our financial performance, achievements, and future goals."} keywords={"Notices, Citi Pharma"}>

                <div className='pdf'>
                    <h1 className='mainHeadings mt-5'>Notices</h1>
                    <iframe src={pdfFile} title="Notices" className='h-100 w-100' />
                </div>
            </Layout >
        </>
    )
}
