import React from 'react'
import Layout from './Layout';
import { MdDownload } from "react-icons/md";

export default function Balloting() {

    // Function to trigger image download
    const downloadImage = () => {
        const link = document.createElement('a');
        link.href = "../images/Balloting.jpeg"; // The image file path
        link.download = 'Balloting-paper.jpeg'; // The name the file will be downloaded as
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <Layout title={"Citi Pharma: Balloting (2024)"} description={"Access Citi Pharma's Balloting for (2024). Explore our financial performance, achievements, and future goals."} keywords={"Balloting, Citi Pharma"}>
                <div className='p-2 p-md-4 p-lg-5 mt-5'>
                    <h1 className='mainHeadings'>Balloting</h1>
                    {/* Download button */}
                    <div className="text-end">
                        <button onClick={downloadImage} className='btn btn-primary fs-3 p-2 py-0'>
                            <MdDownload />
                        </button>
                    </div>
                    {/* Display the image */}
                    <img src="../images/Balloting.jpeg" alt="Balloting" className='my-4 w-100' />

                </div>
            </Layout>
        </>
    )
}

