import React from 'react'
import Layout from './Layout'
import pdfFile from '../../assets/Pattern-Shareholding.pdf'

export default function PatternShareholding() {
    return (
        <>
            <Layout title={"Citi Pharma: Pattern of Shareholding (2024)"} description={"Access Citi Pharma's Pattern of Shareholding for (2024). Explore our financial performance, achievements, and future goals."} keywords={"Pattern of Shareholding, Citi Pharma"}>
                <div className='pdf'>
                    <h1 className='mainHeadings mt-5'>Pattern of Shareholding</h1>
                    <iframe src={pdfFile} title="Pattern of Shareholding" className='h-100 w-100' />
                </div>
            </Layout >
        </>
    )
}
